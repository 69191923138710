import {
  BareCompany,
  CompanyThankYou,
  InitializeReviewProcessResponse,
  Invite,
  Review,
} from '../types/ApiTypes';
import { StepState } from '../types/PropsTypes';

//Challenges: determine what the next step is, keeping the information of the current invite throughout, measuring the review, making things reusable
//Rating-review: rating, review options, written/video review, thank you page
//Review-rating: review, rating, written/video review, thank you page

export function formatInitReviewResponse(
  initReviewResponse: any,
): InitializeReviewProcessResponse {
  return {
    jwtToken: initReviewResponse.jwtToken,
    company: {
      name: initReviewResponse.company.companyname,
      logo: initReviewResponse.company.logo_url,
      defaultCountry: initReviewResponse.company.mailing_country_iso,
      serviceReview: initReviewResponse.company.service_review,
      uniqueid: initReviewResponse.company.uniqueid,
      firstPage: initReviewResponse.company.settings.new_invite_first_page,
      allowAnonymous: initReviewResponse.company.allowAnonymous,
      minStarAllowExternal:
        initReviewResponse.company.settings.min_star_allow_external,
      platformOptions: initReviewResponse.platformOptions,
      thankYou: {
        sharingFormat:
          initReviewResponse.company.settings.thank_you.sharing_format,
        redirectUrl:
          initReviewResponse.company.settings.thank_you.thank_you_page_url,
      },
      isNPS: initReviewResponse.company.isNPS,
      isHipaa:  initReviewResponse.company.settings.hipaa.enabled,
    },
    invite: initReviewResponse.invite,
    review: initReviewResponse.review,
  };
}

export function formatGetClientInviteFormInfoResponse(
  clientInviteFormInfoResponse: any,
): BareCompany {
  return {
    name: clientInviteFormInfoResponse.company.companyname,
    logo: clientInviteFormInfoResponse.company.logo_url,
    defaultCountry: clientInviteFormInfoResponse.company.mailing_country_iso,
    serviceReview: clientInviteFormInfoResponse.company.service_review,
    allowAnonymous: clientInviteFormInfoResponse.company.allowAnonymous
  };
}

export function getThankYouPage(
  thankYouSetting: CompanyThankYou['sharingFormat'],
  rating: number,
  minStarAllowExternal: number,
): CompanyThankYou['sharingFormat'] {
  if (rating < minStarAllowExternal) {
    return null;
  } else {
    return thankYouSetting;
  }
}

export const notExternalOptions = ['SoTellUs', 'SoTellUs Video'];

export function getPlatformOptionReviewType(
  domId: string,
): 'text' | 'video' | undefined {
  if (domId === 'sotellus') {
    return 'text';
  } else if (domId === 'stuvideo') {
    return 'video';
  }

  return undefined;
}

export function convertDomId(domId: string): string {
  if (domId === 'sotellus' || domId === 'stuvideo') {
    return 'stu';
  }

  return domId;
}

export function afterRatingReviewPlatformClick(
  platform: string
): StepState {
  if (platform === 'sotellus') {
    return 'written';
  } else if (platform === 'stuvideo') {
    return 'video';
  } else {
    return 'thankYou';
  }
}

export function afterReviewRatingPlatformClick(platform: string) {
  if (platform === 'sotellus' || platform === 'stuvideo') {
    return 'rating';
  } else if (platform == 'feedback') {
    return 'feedback';
  } 
  else {
    return 'thankYou';
  }
}

export function getRatingReviewStep(
  review: Review,
  minStarAllowExternal: number,
): StepState {
  if (!review.stars) {
    return 'rating';
  } else if (!review.platform && review.stars < minStarAllowExternal) {
    return 'written';
  } else if (!review.platform) {
    return 'platform';
  } else if (
    review.platform &&
    review.platform === 'SoTellUs' &&
    (review.type === 'rating' || review.type === 'text') &&
    !review.review
  ) {
    return 'written';
  } else if (
    review.platform &&
    review.platform === 'SoTellUs' &&
    review.type === 'video' &&
    !review.hasMedia
  ) {
    return 'video';
  } else {
    if((review.settings?.allowed_to_show_ty_page !== undefined && review.settings?.allowed_to_show_ty_page === false)){
      return 'platform';
    }
    return 'thankYou';
  }
}

export function getReviewRatingStep(
  invite: Invite,
  review?: Review,
): StepState {
  if (!review) {
    if (invite.type) {
      if (invite.type === 'feedback') {
        return 'feedback';
      } else {
        return 'rating';
      }
      /* we don't have a way to attach invite to review if external platform is pressed so if ty page a-link is pressed we will then show ty page.  */
    } else if (!invite.settings?.capture_review_platform || (invite.settings?.allowed_to_show_ty_page !== undefined && invite.settings?.allowed_to_show_ty_page === false)) {
      return 'platform';
    } else {
      return 'thankYou';
    }
  } else {
    if (
      review.platform &&
      review.platform === 'SoTellUs' &&
      (review.type === 'rating' || review.type === 'text') &&
      !review.review
    ) {
      return 'written';
    } else if (
      review.platform &&
      review.platform === 'SoTellUs' &&
      review.type === 'video' &&
      !review.hasMedia
    ) {
      return 'video';
    } else {
      return 'thankYou';
    }
  }
}

export function isVideoOnly() {
  return window.location.href.includes('video');
}

export function isTextOnly() {
  console.log(window.location.href)
  return window.location.href.includes('text');
}
