import {useContext, useMemo} from 'react';
import { ReviewContext } from '../../contexts/ReviewContext';
import { CompanyContext } from '../../contexts/CompanyContext';
import { PlatformOption } from '../../types/ApiTypes';
import { notExternalOptions } from '../../services/ReviewProcessService';
import { FallbackThankYouPage } from './FallbackThankYouPage';
import { PlatformButton } from '../PlatformButton';
import { CopyButton, Button, Box } from '@mantine/core';
import { InviteContext } from '../../contexts/InviteContext';
import { IconCopy } from '../icons/TablerIcons';

export function GetOtherReviews(): JSX.Element {
  const { review } = useContext(ReviewContext);
  const { invite } = useContext(InviteContext);
  const { company } = useContext(CompanyContext);

  const otherExternalOptions = useMemo(() => {
    let notAllowedOptions: string[] = [];
    if (review) {
      notAllowedOptions = notExternalOptions.concat(
        review.platform ? review.platform : '',
      );
    } else {
      notAllowedOptions = notExternalOptions.concat(
        invite?.settings?.capture_review_platform
          ? invite.settings.capture_review_platform
          : '',
      );
    }
    if (!company?.platformOptions) {
      return [];
    } else {
      return company.platformOptions.filter(
        (platformOption) =>
          platformOption.name === 'feedback' &&
          !notAllowedOptions.includes(platformOption.name) &&
          !notAllowedOptions.includes(platformOption.DOMid),
      );
    }
  }, []);

  const twoSteps = Boolean(review?.review);

  if (otherExternalOptions.length > 0) {
    return (
      <div className="centered-stack">
        <h2>Leave a Review on Another Site</h2>

        {twoSteps ? (
          <>
            <div className="stack">
              <div className="stack">
                <h4>Step 1</h4>
                <CopyButton value={review?.review ? review.review : ''}>
                  {({ copied, copy }) => (
                    <Button
                      radius={'lg'}
                      color={copied ? 'teal' : 'blue'}
                      onClick={copy}
                    >
                      <IconCopy color="white" />
                      <Box pl={'xs'}>
                        {copied ? 'Copied Review' : 'Copy Review to Clipboard'}
                      </Box>
                    </Button>
                  )}
                </CopyButton>
              </div>
              <div className="stack">
                <h4>Step 2</h4>
                {otherExternalOptions.map((writtenButton: PlatformOption) => (
                  <PlatformButton
                    key={writtenButton.name}
                    writtenButton={writtenButton}
                    onClick={() => window.open(writtenButton.url)}
                  />
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="centered-stack">
            {otherExternalOptions.map((writtenButton: PlatformOption) => (
              <PlatformButton
                key={writtenButton.name}
                writtenButton={writtenButton}
                onClick={() => window.open(writtenButton.url)}
              />
            ))}
          </div>
        )}
      </div>
    );
  } else {
    return <FallbackThankYouPage />;
  }
}
