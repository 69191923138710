import { hasLength, useForm } from '@mantine/form';
import { FeedbackFormInput, } from '../types/FormTypes';
import { FeedbackProps } from '../types/PropsTypes';
import {  useRef, useState } from 'react';
import { Box, Button, Textarea } from '@mantine/core';
import ReCAPTCHA from 'react-google-recaptcha';
import RecaptchaText from './recpatcha/RecaptchaText';

export function FeedbackForm({ onSubmit }: FeedbackProps): JSX.Element {

  const recaptchaRef = useRef<ReCAPTCHA|null>(null);
  const [submitting, setSubmitting] = useState(false);
  const form = useForm<FeedbackFormInput>({
    initialValues: {
      feedback: '',
    },
    validate: {
      feedback: hasLength(
        { min: 4, max: 3000 },
        'Feedback must be more than 4 characters long and less than 3000 characters',
      ),
    },
  });

  return (
    <>
      <form
        className="centered-stack"
        data-clarity-unmask={true}
        onSubmit={form.onSubmit(async(values) => {
          setSubmitting(true);
          let recaptchaToken=null;
          try {
            if (recaptchaRef.current) {
              recaptchaRef.current.reset();
              recaptchaToken = await recaptchaRef.current.executeAsync();
            }
          } catch(e){}
            
          onSubmit(values.feedback, recaptchaToken);
          
        })}
      >
        <h4 style={{marginTop: 20, marginBottom: 20}} className="header-text">
            Please let us know your feedback in the box below:
        </h4>
        <Textarea
          styles={{
            input: { minHeight: '125px' },
          }}
          w="100%"
          placeholder='Leave feedback here....'
          {...form.getInputProps('feedback')}
          mb={10}
        />
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}
        />
        <Button
          type="submit"
          w={'100%'}
          color="green.7"
          size="md"
          radius={'sm'}
          loading={submitting}
        >
          Submit
        </Button>
        <Box mt={10}>
            <RecaptchaText />
        </Box>
        <p className="legal-jargon">
        </p>
      </form>
    </>
  );
}
